
import { defineComponent, onMounted, ref, watch } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
import store from "@/store";

export default defineComponent({
  name: "CZPositionReportDashboard",
  components: {},
  setup() {
    const dashboard = ref({
      savings_budget: "",
      mtm: "",
      budget: "",
      baseline: "",
      mtm_cost: "",
      budget_cost: "",
      Fixed: "",
      market: "",
      lockedSavings: "",
      Supplier: "",
      AccountManager: "",
    });

    const loading = ref<boolean>(false);

    onMounted(() => {
      setCurrentPageBreadcrumbs("Dashboard", ["Flexible Procurement"]);
      getDashboardContent();
    });

    watch(
      () => [
        store.getters.layoutConfig("flexibleProcurement.scenarioName"),
        store.getters.layoutConfig("flexibleProcurement.reportName"),
        store.getters.layoutConfig("flexibleProcurement.current"),
      ],
      function () {
        getDashboardContent();
      }
    );

    function getDashboardContent() {
      loading.value = true;
      let AccountId = store.getters.AccountId;
      let current = store.getters.layoutConfig("flexibleProcurement.current");
      let FPreportName = store.getters.layoutConfig(
        "flexibleProcurement.reportName"
      );
      let FPscenarioName = store.getters.layoutConfig(
        "flexibleProcurement.scenarioName"
      );

      const accountInfo: any = {
        clientId: AccountId,
        productId: 4,
        reportId: FPreportName,
        scenarioId: FPscenarioName,
        iscurrent: current,
      };
      ApiService.post("api/FlexProcurement/getFlexProcrement", accountInfo)
        .then(async ({ data }) => {
          const response = await data;

          dashboard.value = {
          
            savings_budget: response.cumulative_values[1].savings_budget,
            mtm: response.cumulative_values[1].mtm,
            budget: response.cumulative_values[1].budget,
            baseline: response.cumulative_values[1].baseline,
            mtm_cost: response.cumulative_values[1].mtm_cost,
            budget_cost: response.cumulative_values[1].budget_cost,
            Fixed: response.costSummary.Fixed,
            market: response.costSummary.market,
            lockedSavings: response.locked_savings,
            Supplier: response.supplier_Name,
            AccountManager: response.manager,
          };

          loading.value = false;
        })
        .catch();
    }

    /**
     *@description For adding the euro symbol and comma separaters
     */
    // function StringFormat(value) {
    //   let val = new String(value);
    //   let vals = val.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    //   return vals;
    // }

    return {
      dashboard,
     // StringFormat,
      loading,
    };
  },
});
